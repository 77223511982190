import _extends from "/home/vsts/work/1/s/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/home/vsts/work/1/s/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["className", "children", "inheritedStyles", "type", "disabled", "styleType", "ariaLabel"];
var __jsx = React.createElement;
/**
 * Represents a Button Atom.
 * @author kkum
 */import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import styles from './Button.style';
/**
 * Button component.
 * @param {string} className - Class of the tag.
 * @param {Node} children - child nodes.
 * @param {string} inheritedStyles - inherited styles.
 * @param {string} type - button type eg submit.
 * @param {union type} styleType - Primary/Secondary button
 * @param {boolean} disabled - Disabled button
 */
var Button = function Button(_ref) {
  var className = _ref.className,
    children = _ref.children,
    inheritedStyles = _ref.inheritedStyles,
    type = _ref.type,
    disabled = _ref.disabled,
    styleType = _ref.styleType,
    ariaLabel = _ref.ariaLabel,
    others = _objectWithoutProperties(_ref, _excluded);
  return __jsx("button", _extends({
    "aria-label": ariaLabel || null,
    className: cn('nva-button', styleType === 'primary-anchor' || styleType === 'secondary-anchor' || styleType === 'tertiary-anchor' ? 'nva-button--cta' : '', styleType === '' ? '' : "nva-button--".concat(styleType), className),
    disabled: disabled,
    type: type
  }, others), children);
};
Button.defaultProps = {
  inheritedStyles: '',
  type: 'button',
  styleType: '',
  disabled: false,
  ariaLabel: ''
};
export default styled(Button).withConfig({
  componentId: "sc-1xler7b-0"
})(["", ";"], styles);
export { Button as ButtonVanilla };