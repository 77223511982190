import _extends from "/home/vsts/work/1/s/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/home/vsts/work/1/s/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["className", "children", "dataSlnmId", "inheritedStyles"];
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import Heading from '../Heading';
import styles from './FieldError.style';
var FieldError = function FieldError(_ref) {
  var className = _ref.className,
    children = _ref.children,
    dataSlnmId = _ref.dataSlnmId,
    inheritedStyles = _ref.inheritedStyles,
    others = _objectWithoutProperties(_ref, _excluded);
  return __jsx(Heading, _extends({
    HeadingType: "h5",
    className: "nva-field-error ".concat(className),
    "aria-live": "assertive",
    role: "alert"
  }, others), children);
};
FieldError.defaultProps = {
  inheritedStyles: '',
  className: ''
};
export default styled(FieldError).withConfig({
  componentId: "sc-1whhxqp-0"
})(["", ";"], styles);
export { FieldError as FieldErrorVanilla };