import _extends from "/home/vsts/work/1/s/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/home/vsts/work/1/s/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["id", "className", "type", "title", "name", "value", "label", "placeholder", "ariaLabel"];
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import styles from './Input.style';
var Input = function Input(_ref) {
  var id = _ref.id,
    className = _ref.className,
    type = _ref.type,
    title = _ref.title,
    name = _ref.name,
    value = _ref.value,
    label = _ref.label,
    placeholder = _ref.placeholder,
    ariaLabel = _ref.ariaLabel,
    others = _objectWithoutProperties(_ref, _excluded);
  return __jsx("input", _extends({
    id: id,
    "aria-label": ariaLabel,
    className: "nva-input ".concat(className),
    name: name,
    type: type,
    value: value,
    placeholder: placeholder
  }, others));
};
Input.defaultProps = {
  placeholder: '',
  ariaLabel: '',
  className: '',
  type: 'text',
  value: ''
};
export default styled(Input).withConfig({
  componentId: "sc-69e7p9-0"
})(["", ";"], styles);
export { Input as InputVanilla };